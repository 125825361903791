// @flow

import React from 'react';

import HtmlHead from '../components/HtmlHead';

export default function NotFound(props: Object) {
  return (
    <>
      <HtmlHead title='404 puslapis nerastas | bukitesveiki.lt.' meta={{robots: 'noindex'}} />
      <h1>Nepavyko atrasti šio puslapio.</h1>
    </>
  );
}
